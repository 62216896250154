(($) => {
    jQuery(function () {

        // Accordéon offres d'emploi
        if ($('.jobs-item').length) {
            let $jobLabel = $('.jobs-item-label');

            setAccordion('.jobs-item', '.jobs-item-label', '.jobs-item-content');

            $jobLabel.each((i, el) => {
                let jobLabelHeight = $(el).outerHeight(),
                    $jobWrap = $(el).parents('.jobs-item-label-wrap')
                    ;
                $jobWrap.height(jobLabelHeight);
            });
        }

        $('.menu .menu-item-has-children > a, .menu > .page_item_has_children > a ').on('click', (e) => {
            e.preventDefault();
        });

        //------------------------------------------
        // Dotdotdot / Cut des textes trop longs avec "..."
        //------------------------------------------

        $('.dotdotdot').dotdotdot({
            ellipsis: '...',
            truncate: 'word',
            fallbackToLetter: true,
            watch: 'window',
            tolerance: 0,
            callback: function (isTruncated, orgContent) { },
            lastCharacter: {
                remove: [' ', ',', ';', '.', '!', '?'],
                noEllipsis: []
            }
        });

        // Switch lang
        $('.switch-lang').on('click', (e) => {
            $(e.currentTarget).addClass('isOpen');
        });
        $('.switch-lang .current-lang a').on('click', (e) => {
            e.preventDefault();
        });
        // Close all dropdown onclick on another element
        $(document).bind('click.switch-lang', function (e) {
            if (!$(e.target).parents().hasClass("switch-lang") && !$(e.target).hasClass('switch-lang')) {
                $(".switch-lang").removeClass('isOpen');
            }
        });


        headerOnScroll();

        // Polyfill object fit images
        objectFitImages(".imageFit img");


        //------------------------------------------
        // Bouton menu
        //------------------------------------------
        const $body = $('body'),
            $header = $('.SiteHeader'),
            jSideMenu = $('.SiteHeader-inner')
            // jSubmenu = $('.SideMenu .submenu')
            ;
        let scrollbarWidth = getScrollbarWidth();

        $('.js-btnMenu').click(function (e) {

            if ($body.hasClass('isMenuOpen')) {
                $body.add($header).css({ 'padding-right': 0 });
                $body.removeClass('isMenuOpen');
                TweenLite.fromTo(jSideMenu, .5, { autoAlpha: 1 }, { autoAlpha: 0, ease: Power1.easeOut });
                // jSubmenu.delay(600).removeClass('isOpen');
            } else {
                $body.add($header).css({ 'padding-right': scrollbarWidth });
                $body.addClass('isMenuOpen');
                // TweenLite.set(jSideMenu, {'display':'block'});
                TweenLite.fromTo(jSideMenu, .5, { autoAlpha: 0 }, { autoAlpha: 1, ease: Power1.easeOut });
            }

            if (!($(e.currentTarget).hasClass('js-btnMenu-anchor'))) {
                return false;
            }
        });
        $('.menu-overlay').click(function () {
            if ($body.hasClass('isMenuOpen')) {
                $body.add($header).css({ 'padding-right': 0 });
                $body.removeClass('isMenuOpen');
                TweenLite.fromTo(jSideMenu, .5, { autoAlpha: 1 }, { autoAlpha: 0, ease: Power1.easeOut });
            }
        });

        // Sous-menu (menu burger)
        $('.menu-item-has-children').each((i, el) => {
            const $el = $(el),
                $submenu = $('.sub-menu', $el),
                $otherEl = $('.menu-item-has-children').not($el),
                $otherSubmenu = $('.sub-menu', $otherEl)
                ;

            $('> a', $el).click((e) => {
                e.preventDefault();

                $otherEl.removeClass('submenu-open');
                $otherSubmenu.slideUp(400, "swing");

                if ($el.hasClass('submenu-open')) {
                    $submenu.slideUp(400, "swing");
                } else {
                    $submenu.slideDown(400, "swing");
                }
                $el.toggleClass('submenu-open');
            });
        });


        // Affichage bouton contact sticky
        if ($('.sticky-button').length) {
            if ($('.cover').length) {
                const $coverHeight = $('.cover').outerHeight();

                $(window).on('scroll.sticky', () => {
                    if ($(window).scrollTop() > $coverHeight) {
                        $('.sticky-button').addClass('is-visible');
                    }
                });
            } else {
                $('.sticky-button').addClass('is-visible');
            }
        }

        // VideoFrame
        $(".js-VideoFrame").each((i, el) => {
            const $el = $(el);
            $(el).videoframe();

            // Video click on Cover
            const $cover = $el.parents('.Video').find('.Video-cover');
            $cover.on('click', (e) => {
                e.preventDefault();

                if (!$el.data('video')) {
                    return;
                }

                $(e.currentTarget).fadeOut(500);
                $(el).videoframe('play');
            });
        });


        // Formulaires
        jQuery(document).on('gform_post_render', function (event, form_id, current_page) {
            // code to trigger on form or form page render

            $('.gfield_error input, .gfield_error textarea, .gfield_error select').on('focus', (e) => {
                $(e.target).addClass('is-ok');
                $(e.target).parents('.ginput_container').siblings('.validation_message').css({ "opacity": 0, "visibility": "hidden" });
            });
            // Une fois que le fichier a été uploadé, on affiche le filename dans le label (CSS et style obligent)
            $('.gfield input[type="file"]').on("change", (e) => {
                $(e.target).parents('.ginput_container').siblings('label').attr('data-before', e.target.value);
            });
            $('.gfield_error input[type="file"]').on("change", (e) => {
                $(e.target).parents('.ginput_container').siblings('label').addClass('is-ok');
                $(e.target).parents('.ginput_container').siblings('.validation_message').css({ "opacity": 0, "visibility": "hidden" });
            });
        });

        jQuery('form .gform_button').on('click', (e) => {
            $(e.currentTarget).after('<img src="' + baseUrl + '/assets/img/forms/spinner.svg" class="spinner" aria-hidden="true">');
        });

    });


    // Functions

    function getScrollbarWidth() {
        return window.innerWidth - document.documentElement.clientWidth;
    }

    function smoothScroll(element = $('html, body'), dest = 0, speed = 500) {
        element.animate({
            scrollTop: dest
        }, speed);
    }

    function headerOnScroll() {
        var scrollTop = $(window).scrollTop(),
            headerHeight = $('.SiteHeader').outerHeight(true)
            ;

        function shrinkIt() {
            if (scrollTop > headerHeight) {
                $('body').addClass('headerFixed');
            } else {
                $('body').removeClass('headerFixed');
            }
        }

        shrinkIt();

        $(window).scroll(function () {
            scrollTop = $(window).scrollTop();

            shrinkIt();
        });
    }

    function setAccordion(elem, trigger = '.js-accordeonTrigger', content = '.accordeon-content') {
        let $refEl = $(elem),
            $allTrigger = $(trigger),
            $allContent = $(content)
            ;

        $refEl.each(function (i, el) {
            let $el = $(el),
                $trigger = $(trigger, $el),
                $content = $(content, $el)
                ;

            $trigger.click(function (e) {

                if ($(e.target).is('a') || $(e.target).parent().is('a')) {
                    return;
                }

                e.preventDefault();

                if ($el.hasClass('is-open')) {
                    $el.removeClass('is-open');
                    $content.slideUp(500, "swing", () => {
                        $el.addClass('is-collapsed');
                    });
                } else {
                    $refEl.not($(e.currentTarget).parent()).removeClass('is-open');
                    $refEl.not($(e.currentTarget).parent()).addClass('is-collapsed');
                    $allContent.not($content).slideUp(500, "swing");

                    $el.addClass('is-open');
                    $content.slideDown(500, "swing", () => {
                        $el.removeClass('is-collapsed');
                        smoothScroll($('html, body'), $el.offset().top - 80, 500);
                    });
                }
            });
        });

    }

})(jQuery)
