/**
 *
 * Plugin jquery videoframe
 * @version : 1.2.2 modification du nom de variable interface en itf pour compat es6
 * @version : 1.2.1 bug avec vimeo.player, difficulté de récup Vimeo après chargement en async
 * @version : 1.2 intégration vimeo.player
 * @version : 1.1.1 (intégration numeridanse.tv) + correction de qq bugs
 * @author: Marc Loyat dankastudio - mailto:marc@dankastudio.fr
 *
 * Plugin jquery qui permet de charger et controler une vidéo hébergée sur youtube, vimeo, dailymotion ou numeridanse
 * en fournissant l'url de la page de la vidéo sur la plateforme
 *
 * Vocabulaire :
 	* Nous appelons ici "frame" la zone d'affichage dans laquelle sera chargée la vidéo

 * Prérequis css
 	* La frame doit avoir les attributs css suivants :
		* display : block|inline-block
		* position : relative|absolute|fixed
		* overflow : hidden
	* Il est recommandé que la frame ait les attributs css suivants :
		* background : black
	*
	* Par ailleurs la frame doit avoir une taille définie, les players vidéo prendront 100% de la zone

 * Exemple de html

	<div class="js-VideoFrame VideoSlide" data-video="https://www.youtube.com/watch?v=vnWS6b_tP1k"></div>

 * Exemple de css associé

	.js-VideoFrame{
		overflow:hidden;
		position:relative;
		width:100%;
		background: #000;

	}
	.VideoSlide{
		width:800px;
		height:580px;
	}

 *
 *
 * Attributs html :
 	* @data-video - l'url de la page de la vidéo
 *
 *
 * Paramètre JS
 	* L'appel de la fonction jquery peut se faire selon les modalités suivantes
		* $(".monelement").videoframe();
		* $(".monelement").videoframe(settings);
		* $(".monelement").videoframe(action);
	*
	* @settings:Object - permet de régler quelques options du plugin
		* autoplay:Boolean (false par défaut) - permet de lancer automatiquement la vidéo quand c'est possible
	*
	* @action:String - indique au plugin une action à réaliser sur l'élément déjà initialisé
		* Valeurs attendues :
			* "play" - peremet de lancer la lecture de la vidéo
			* "stop" - peremet d'arrêter la lecture de la vidéo
 *
 *
 */
(function($){

	// compteur pour créer des id de player uniques
	var uniqueID = 0;

	// stockage des interfaces video par id
	var registre = {};

	// un p'tit helper pour générer un code iframe
	function _getIframe(url, id){
		var str = '<iframe id="{id}" src="{url}" width="100%" height="100%" scrolling="no" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>';
		return str.replace(/\{url\}/, url).replace(/\{id\}/, id);
	}

	$.fn.videoframe = function(){

		var options = null,
			action = null,
			arg
		;
		// arguments
		for(var i = 0, l = arguments.length; i<l; i++){
			arg = arguments[i];
			if (typeof(arg) == "object"){
				options = arg;
			}
			if(typeof(arg) == "string"){
				action = arg.toLowerCase();
			}
		}

		// les actions
		// lance le resize à la mano de l'image
		var ACTION_PLAY 			= "play";
		var ACTION_STOP 			= "stop";
		var ACTION_PAUSE 			= "pause";

		// les providers connus
		var VIDEO_PROVIDER_YOUTUBE = "youtube";
		var VIDEO_PROVIDER_DAILYMOTION = "dailymotion";
		var VIDEO_PROVIDER_VIMEO = "vimeo";
		var VIDEO_PROVIDER_NUMERIDANSE = "numeridanse";
		var VIDEO_PROVIDER_UNKNOWN = null;


		function _detectProvider(url){
			if(YoutubeInterface.recognize(url)){
				return VIDEO_PROVIDER_YOUTUBE;
			}
			if(DailyMotionInterface.recognize(url)){
				return VIDEO_PROVIDER_DAILYMOTION;
			}
			if(VimeoInterface.recognize(url)){
				return VIDEO_PROVIDER_VIMEO;
			}
			if(NumeridanseInterface.recognize(url)){
				return VIDEO_PROVIDER_NUMERIDANSE;
			}
		}


		if(action == ACTION_PLAY){
			return this.each(function(){
				var jframe = $(this),
					id = jframe.attr("id"),
					itf = registre[id]
				;
				if (itf){
					itf.play();
				}
			});
		}


		if(action == ACTION_STOP){
			return this.each(function(){
				var jframe = $(this),
					id = jframe.attr("id"),
					itf = registre[id]
				;
				if (itf){
					itf.stop();
				}
			});
		}


		if(action == ACTION_PAUSE){
			return this.each(function(){
				var jframe = $(this),
					id = jframe.attr("id"),
					itf = registre[id]
				;
				if (itf){
					itf.pause();
				}
			});
		}



		return this.each(function(){

			var jthis = $(this),
				videoPageUrl = jthis.attr("data-video"),
				videoProvider = _detectProvider(videoPageUrl),
				itf
			;


			var id = jthis.attr("id"),
				itf = registre[id]
			;
			if (itf){
				return;
			}


			var settings = $.extend({
				autoplay:false,
				interface:true,
			}, options);

			switch(videoProvider){
				case VIDEO_PROVIDER_YOUTUBE :
					itf = new YoutubeInterface(jthis, videoPageUrl, settings)
					break;
				case VIDEO_PROVIDER_DAILYMOTION :
					itf = new DailyMotionInterface(jthis, videoPageUrl)
					break;
				case VIDEO_PROVIDER_VIMEO :
					itf = new VimeoInterface(jthis, videoPageUrl)
					break;
				case VIDEO_PROVIDER_NUMERIDANSE :
					itf = new NumeridanseInterface(jthis, videoPageUrl, settings)
					break;
			}

			if(!itf){
				return;
			}

			if(settings.autoplay){
				itf.play();
			}

		});

	};

	//--------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------
	// ------------------------- INTERFACE NEUTRE ------------------------------------------
	//--------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------

	function PlayerInterface(jframe, videoURL){

		if(!arguments.length){return;}
		this.jframe = jframe;
		this.videoID = this.extract(videoURL);

		var id = "videoframe_" + uniqueID++;
		jframe.attr("id", id);

		registre[id] = this;

	}



	/**
	 * stockage de la référence vers l'élément html frame
	 */
	PlayerInterface.prototype.jframe = null;

	/**
	 * stockage de l'id de la vidéo à charger
	 */
	PlayerInterface.prototype.videoID = null;

	/**
	 * indique si l'on souhaite que la vidéo se lance automatiquement lorsqu'elle est prête
	 */
	PlayerInterface.prototype.autoplay = false;

	/**
	 * indique que le player est prêt
	 */
	PlayerInterface.prototype.ready = false;

	/**
	 * extraction de l'id de la vidéo en fonction de l'url de la page vidéo
	 */
	PlayerInterface.prototype.extract = function(url){
		// override
		return null;
	};


	PlayerInterface.prototype.play = function(){};
	PlayerInterface.prototype.stop = function(){};
	PlayerInterface.prototype.onReady = function(){};
	/**
	 * onReady interne
	 */
	PlayerInterface.prototype._onPlayerReady = function(){};




	//--------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------
	// ------------------------- NUMERIDANSE -----------------------------------------------
	//--------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------

	function NumeridanseInterface(jframe, videoURL, settings){

		// détection de la présence de l'api youtube
		NumeridanseInterface.detect();

		// super
		PlayerInterface.apply(this, arguments);
		this.settings = settings;
		this._init();

	}

	NumeridanseInterface.prototype = new PlayerInterface();


	NumeridanseInterface.prototype.pause = function(){
		if(this.iframe){
			this.iframe.contentWindow.postMessage("pause", "*");
		}
		else {
			this.autoplay = false;
		}
	};

	NumeridanseInterface.prototype.stop = function(){
		if(this.iframe){
			this.iframe.contentWindow.postMessage("stop", "*");
		}
		else {
			this.autoplay = false;
		}
	};
	NumeridanseInterface.prototype.play = function(){
		if(this.iframe){
			this.iframe.contentWindow.postMessage("play", "*");
		}
		else{
			this.autoplay = true;
		}
	};
	NumeridanseInterface.prototype._init = function(){

		var refThis = this,
			jframe = this.jframe,
			videoID = this.videoID,
			playerID = "nd" + videoID ,
			url = "http://www.numeridanse.tv/fr/share/video/embed/" + videoID
		;
		url += '/' + (this.settings.autoplay ? '1' : '0');// autostart
		url += '/' + (this.settings.interface ? '1' : '0');// planquer les commandes

		jframe.append(_getIframe(url, playerID));

		this.iframe = $("iframe", jframe)[0];

	};

	NumeridanseInterface.prototype.extract = function(url){
		// http://numeridanse.tv/fr/video/3758_rites
		//http://numeridanse.tv/fr/share/video/embed/3407
		var match = url.match(/.*\/(\d+)_.*/);
		if(!match){
			match = url.match(/.*\/(\d+)$/);
		}
  		return match ? match[1] : false;
	}


	// METHODES STATIQUES

	/**
	 * est-ce une vidéo youtube
	 */
	NumeridanseInterface.recognize = function(url){
		return url.match(/numeridanse\.tv\//);
	}
	/**
	 * indique que l'api js est dispo
	 */
	NumeridanseInterface.apiReady = true;

	/**
	 * détection
	 */
	NumeridanseInterface.detect = function(){

	};


	//--------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------
	// ------------------------- VIMEO -----------------------------------------------------
	//--------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------

	function VimeoInterface(jframe, videoURL){

		// détection de la présence de l'api vimeo
		VimeoInterface.detect();

		// super
		PlayerInterface.apply(this, arguments);
		this.asyncInit();

	}

	VimeoInterface.prototype = new PlayerInterface();

	VimeoInterface.prototype.asyncInit = function(){
		if(!VimeoInterface.apiReady){
			var refThis = this;
			return setTimeout(function(){refThis.asyncInit()}, 100);
		}
		this._init();
	};

	VimeoInterface.prototype.stop = VimeoInterface.prototype.pause = function(){
		if(this.ready){
			this.player.pause();
		}
		else {
			this.autoplay = false;
		}
	};
	VimeoInterface.prototype.play = function(){
		if(this.ready){
			this.player.play();
		}
		else{
			this.autoplay = true;
		}
	};
	VimeoInterface.prototype._onPlayerReady = function(){
		this.ready = true;
		this.onReady();
	};
	VimeoInterface.prototype._init = function(){
		// on passe par le mode iframe qui permet width et height à 100%
		var refThis = this,
			jframe = this.jframe,
			videoID = this.videoID,
			playerID = "vm" + videoID ,
			url = "https://player.vimeo.com/video/" + videoID + "?byline=false&portrait=false&title=false"
		;
		if(this.autoplay){
			url += "&autoplay=true";
		}

		jframe.append(_getIframe(url, playerID));

		this.iframe = $("iframe", jframe)[0];
		// bug depuis upgrade du player vimeo js, pb de récup de l'objet Vimeo après chargement en asynchrone
		//this.player = new Vimeo.Player(this.iframe);
		//this.player.ready().then(function(){refThis._onPlayerReady()});

	};

	VimeoInterface.prototype.extract = function(url){
		// http://stackoverflow.com/questions/13286785/get-video-id-from-vimeo-url
		var vimeo_Reg = /https?:\/\/(?:www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|)(\d+)(?:$|\/|\?)/;
		var match = url.match(vimeo_Reg);
  		return match ? match[3] : false;
	};


	// METHODES STATIQUES

	/**
	 * est-ce une vidéo youtube
	 */
	VimeoInterface.recognize = function(url){
		return url.match(/vimeo\.com\//);
	}
	/**
	 * indique que l'api js est dispo
	 */
	VimeoInterface.apiReady = true;// = false;//bug depuis upgrade du player vimeo js, pb de récup de l'objet Vimeo après chargement en asynchrone

	/**
	 * détection
	 */
	VimeoInterface.detect = function(){

		var s = "https://player.vimeo.com/api/player.js";
		if($("script[src='"+s+"']").length == 0){

			var tag = document.createElement('script');
			tag.src = s;

			var firstScriptTag = document.getElementsByTagName('script')[0];
			firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

		}


		//detect(); // bug depuis upgrade du player vimeo js, pb de récup de l'objet Vimeo après chargement en asynchrone

		function detect(){
			if(typeof(Vimeo) == "undefined"){
				return setTimeout(detect, 80);
			}


			VimeoInterface.apiReady = true;
		}
	};


	//--------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------
	// ------------------------- YOUTUBE ---------------------------------------------------
	//--------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------

	function YoutubeInterface(jframe, videoURL, settings){

		// détection de la présence de l'api youtube
		YoutubeInterface.detect();

		this.settings = settings;

		// super
		PlayerInterface.apply(this, arguments);
		this.asyncInit();

	}

	YoutubeInterface.prototype = new PlayerInterface();

	YoutubeInterface.prototype.asyncInit = function(){
		if(!YoutubeInterface.apiReady){
			var refThis = this;
			return setTimeout(function(){refThis.asyncInit()}, 100);
		}
		this._init();
	};

	YoutubeInterface.prototype.pause = function(){
		if(this.ready){
			this.player.pauseVideo();
		}
		else {
			this.autoplay = false;
		}
	};
	YoutubeInterface.prototype.stop = function(){
		if(this.ready){
			this.player.stopVideo();
		}
		else {
			this.autoplay = false;
		}
	};
	YoutubeInterface.prototype.play = function(){
		if(this.ready){
			this.player.playVideo();
		}
		else{
			this.autoplay = true;
		}
	};
	YoutubeInterface.prototype._onPlayerReady = function(){
		this.ready = true;
		this.onReady();
		if(this.autoplay){
			this.play();
		}
	};
	YoutubeInterface.prototype._init = function(){

		var refThis = this,
			jframe = this.jframe,
			videoID = this.videoID,
			playerID = jframe.attr("id")
		;

		this.player = new YT.Player(playerID, {
			height: '100%',
			width: '100%',
			videoId: videoID,
			playerVars:{
				autoplay: 0,
				controls: (this.settings.interface ? 1 : 0),
				wmode:"opaque",
				// modestbranding:1,
				rel:0,
				showinfo:0
			},
			events: {
				'onReady': function () {refThis._onPlayerReady();}
			}
		});




	};

	YoutubeInterface.prototype.extract = function(url){
		// thx to http://lasnv.net/foro/839/Javascript_parsear_URL_de_YouTube
		var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
		var match = url.match(regExp);
		return (match&&match[7].length==11)? match[7] : false;

	}


	// METHODES STATIQUES

	/**
	 * est-ce une vidéo youtube
	 */
	YoutubeInterface.recognize = function(url){
		// https://youtu.be/zU_npsBDe4s
		// https://www.youtube.com/watch?v=zU_npsBDe4s
		// ->https://www.youtube.com/embed/zU_npsBDe4s?modestbranding=1&controls=0&wmode=opaque&rel=0&showinfo=0
		return url.match(/youtu\.be\/|youtube\.com\//);
	}
	/**
	 * indique que l'api js est dispo
	 */
	YoutubeInterface.apiReady = false;

	/**
	 * détection
	 */
	YoutubeInterface.detect = function(){

		if($("script[src='https://www.youtube.com/iframe_api']").length == 0){

			var tag = document.createElement('script');
			tag.src = "https://www.youtube.com/iframe_api";

			var firstScriptTag = document.getElementsByTagName('script')[0];
			firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

		}

		detectYT();

		function detectYT(){
			if(typeof(YT) == "undefined" || typeof(YT.Player) == "undefined" ){
				return setTimeout(detectYT, 80);
			}
			YoutubeInterface.apiReady = true;
		}
	};


	//--------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------
	// ------------------------- DAILYMOTION------------------------------------------------
	//--------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------

	function DailyMotionInterface(jframe, videoURL){

		// détection de la présence de l'api youtube
		DailyMotionInterface.detect();

		// super
		PlayerInterface.apply(this, arguments);
		jframe.append('<div class="player"></div>');
		this.asyncInit();

	}

	DailyMotionInterface.prototype = new PlayerInterface();

	DailyMotionInterface.prototype.asyncInit = function(){
		if(!DailyMotionInterface.apiReady){
			var refThis = this;
			return setTimeout(function(){refThis.asyncInit()}, 100);
		}
		this._init();
	};

	DailyMotionInterface.prototype.stop = DailyMotionInterface.prototype.pause = function(){
		if(this.ready){
			this.player.pause();
		}
		else {
			this.autoplay = false;
		}
	};
	DailyMotionInterface.prototype.play = function(){
		if(this.ready){
			this.player.play();
		}
		else{
			this.autoplay = true;
		}
	};
	DailyMotionInterface.prototype._onPlayerReady = function(){
		this.ready = true;
		this.onReady();
		if(this.autoplay){
			this.player.play();
		}
	};
	DailyMotionInterface.prototype._init = function(){

		var refThis = this,
			jframe = this.jframe,
			videoID = this.videoID,
			playerID = jframe.attr("id")
		;

		this.player = DM.player($(".player", jframe)[0],
			{
				video:videoID, width:"100%", height:"100%",
				params:{html:1, autoplay:0, info:0, logo:0, related:0, quality:720},
				events:{
					apiready:	function(){refThis._onPlayerReady()}
				}
			}
		);

	};

	DailyMotionInterface.prototype.extract = function(url){
		// http://stackoverflow.com/questions/12387389/how-to-parse-dailymotion-video-url-in-javascript
		var re = /(?:dailymotion\.com(?:\/video|\/hub)|dai\.ly)\/([0-9a-z]+)(?:[\-_0-9a-zA-Z]+#video=([a-z0-9]+))?/g;
		var m;

		while ((m = re.exec(url)) != null) {
			return (m[2]?m[2]:m[1]);
		}
		return false;

	}


	// METHODES STATIQUES

	/**
	 * est-ce une vidéo youtube
	 */
	DailyMotionInterface.recognize = function(url){
		/**
		http://www.dailymotion.com/video/x44lvd_rates-of-exchange-like-a-renegade_music
		http://www.dailymotion.com/video/x44lvd
		http://www.dailymotion.com/hub/x9q_Galatasaray
		http://www.dailymotion.com/login?restricted_action=1&urlback=%2Fupload
		http://www.dailymotion.com/hub/x9q_Galatasaray#video=xjw21s
		http://www.dailymotion.com/video/xn1bi0_hakan-yukur-klip_sport
		http://dai.ly/x44lvd
		/**/
		return url.match(/dailymotion\.com\/|dai\.ly\//);
	}
	/**
	 * indique que l'api js est dispo
	 */
	DailyMotionInterface.apiReady = false;

	/**
	 * détection
	 */
	DailyMotionInterface.detect = function(){

		var s = "http://api.dmcdn.net/all.js";
		if($("script[src='"+s+"']").length == 0){

			var tag = document.createElement('script');
			tag.src = s;

			var firstScriptTag = document.getElementsByTagName('script')[0];
			firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

		}

		detect();

		function detect(){
			if(typeof(DM) == "undefined" || typeof(DM.player) == "undefined" ){
				return setTimeout(detect, 80);
			}
			DailyMotionInterface.apiReady = true;
		}
	};


})(jQuery);
