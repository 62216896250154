// import './lib/jquery.min.js';
import './lib/jquery.videoframe.js';
import './lib/jquery.dotdotdot.js';
// import { TweenLite, TimelineLite, CSSPlugin } from "gsap";

import './global/main.js';

import './class/Slider.js';
import './class/Slider.cover.js';
import './class/Slider.temoins.js';
import './class/Slider.onglets.js';
import './class/Slider.infos-live.js';
import './class/Popin.js';
import './map/Map.js';
