import Slider from './Slider.js';
import Flickity from 'flickity';

class SliderCover extends Slider{
    constructor($self,params = null){

        super($self, params);

        const me = this;

        this.prevCurrent = this.slider.selectedIndex;

        // On init les clicks
        if(this.pagination.length){
            this.initPagination();
        }

        this.slider.on('change', (index)=>{
            this.animateSlider(index);
        });
    }

    initPagination(){
        const me = this;
        this.pagination.eq(me.slider.selectedIndex).addClass('active');
        this.pagination.on('click', (e)=>{
            e.preventDefault();

            if( !this._isActive ){
                let index = $(e.currentTarget).index();
                me.slider.select( index );
                this.pagination.removeClass('active');
                $(e.currentTarget).addClass('active');
            }
        });
    }

    animateSlider(index){
        const me = this;

        if(!this._isActive){
            let $prevSlide = $(this.slider.cells[this.prevCurrent].element),
                $nextSlide =  $(this.slider.cells[index].element),
                $prevSlideImg = $('.slide-img', $prevSlide),
                $prevSlideLines = $('.s-title1, .accroche, .link-wrap', $prevSlide),
                $nextSlideImg = $('.slide-img', $nextSlide),
                $nextSlideLines = $('.s-title1, .accroche, .link-wrap', $nextSlide)
            ;

            let otherSlidesKeys = Object.keys(this.slider.cells).filter((key)=>{
                return key != index && key != me.prevCurrent;
            });

            this._isActive = true;

            const vTL = new TimelineLite({
                onComplete: ()=>{
                    me._isActive = false;
                    me.prevCurrent = me.slider.selectedIndex;
                }
            });

            otherSlidesKeys.forEach((key)=>{
                vTL.set($(this.slider.cells[key].element), {zIndex:0},0);
            });

            vTL.set($nextSlideImg, {autoAlpha:1})
            .staggerFromTo($prevSlideLines, 0.4, {autoAlpha:1, y:0}, {autoAlpha:0, y:-50, force3D:true, ease:Power2.easeOut}, 0.07)
            .fromTo($prevSlideImg, 0.5, {autoAlpha:1}, {autoAlpha:0})
            .set($prevSlide, {zIndex:2})
            .set($nextSlide, {zIndex:3})
            .staggerFromTo($nextSlideLines, 0.4, {autoAlpha:0, y:-50}, {autoAlpha:1, y:0, force3D:true, ease:Power2.easeOut}, 0.07, "-=0.15")
            ;

            return vTL;
        }
    }

}
export default SliderCover;


//------------------------------------------
// Init Slider Cover
//------------------------------------------
$(document).ready(function() {
    const $slider_cover = $('.cover.slider');
    let $slider_stage;

    if($slider_cover.length){
        var sliderCover = [];

        $slider_cover.each((i, el)=>{
            $slider_stage = $('.slider-stage', $(el));

            sliderCover[i] = new SliderCover($(el), {
                draggable: false,
                prevNextButtons: false,
                autoPlay: 5000
            });
        });
    }
});
