document.addEventListener('DOMContentLoaded', function() {

    let infos_live_slider = $('.infos-live-slider'),
        infos_live_slides = $('.infos-live-slide'),
        infos_live_slider_navigation = $('.infos-live-slider-navigation'),
        infos_live_slider_navigation_buttons;
    
    // Ajoute les boutons (puces) de navigation
    if (infos_live_slides.length > 1) {
        $(infos_live_slides).each(function(index) {
            $(infos_live_slider_navigation).append('<span></span>');
        })
    }
    infos_live_slider_navigation_buttons = $(infos_live_slider_navigation).find('span')

    // Passe au slide suivant ou celui spécifié dans l'index (sa position dans le tableau "infos_live_slides")
    var infosLiveNext = (index = null) => {
        let old_slide = $('.infos-live-slide.active');

        if (index === null) {
            $(infos_live_slides).each(function(i) {
                if ($(this).hasClass('active')) {
                    index = (i + 1 > infos_live_slides.length - 1) ? 0 : i + 1;
                }
            })
        }

        $(infos_live_slides).removeClass('active old'); // slide actif
        $(infos_live_slides[index]).addClass('active');
        $(old_slide).addClass('old'); // slide plus actif
        $(infos_live_slider_navigation_buttons).removeClass('active'); // bouton actif
        $(infos_live_slider_navigation_buttons[index]).addClass('active');

        if ($(document).width() < 414) {
            changeSliderHeight();
            changeContainerHeight();
        }
    }

    // Change le slide courant quand on clique sur les boutons (puces) de navigation
    $(infos_live_slider_navigation_buttons).each(function(i) {
        let index = i;

        $(this).on('click', function() {
            if (!$(this).hasClass('active')) {
                infosLiveNext(index);
                setSliderInterval();
            }
        })
    })

    // Change la hauteur du slider sur mobile
    var changeSliderHeight = () => {
        let height = $('.infos-live-slide.active').height();
        $(infos_live_slider).height(height);
    }

    // Change la hauteur du container avant le slider (pour qu'il soit fixé en bas)
    var changeContainerHeight = () => {
        let container = $('.home .cover .container'),
            height = $(window).height() - (60 + 25 + $('.infos-live > h3').outerHeight() + $('.infos-live-slider-navigation').outerHeight() + $('.infos-live-slide.active').outerHeight());

        $(container).height(height);
    }

    var setSliderInterval = () => {
        clearInterval(infos_live_interval)
        infos_live_interval = setInterval(infosLiveNext, 5000);
    }

    // INIT
    // Affiche le premier slide, le bouton courant et la bonne hauteur
    $(infos_live_slides[0]).addClass('active');
    $(infos_live_slider_navigation_buttons[0]).addClass('active');
    changeSliderHeight();
    changeContainerHeight();
    let infos_live_interval = (infos_live_slides.length > 1) ? setInterval(infosLiveNext, 5000) : '';
})