import "leaflet";
import "leaflet.markercluster";

export default class Map {
    constructor(params = null){

        let _defaultParams = {
            element: 'Map',
            data: null,
            center: [45,5],
            zoom: 8,
            zoomControl: false,
            scrollWheelZoom: false,
            maxBounds: [
                [60.59, -30.76], [29.99, 33.05]
            ]
        }
        const arrayJSON = $("[data-map='map']").text();
        // Si il y a des paramètres en plus, on vient fusionner l'objet par defaut avec le nouveau.
        this.params = $.extend(_defaultParams,params);

        this.$parent = $(this.params.parent);
        this.$el = this.params.element;
        this.mapData = JSON.parse(arrayJSON);

        this.map = null;
        this.markers = {};
        this.markersLayer = null;

        // Filtres HTML
        this.filters = [];
        this.$listFilters = this.$parent.find('.map-filters a');

        // this.addSiegeItem();

        this.initMap();

    }
    addSiegeItem(){
        let siege = {
            id: 1,
            adresse_1: "ZI La Tulandière",
            adresse_2: "2 Route de la Maison Blanche",
            categories: ["siege"],
            city: "Saint-Rambert-d'Albon",
            lat: "45.27829",
            lng: "4.82059",
            mail: "",
            resp: "",
            tel: "",
            title: "Siège Social",
            zipcode: "26140"
        };

        this.mapData.push(siege);

    }
    initMap() {
        if(L.Browser.mobile && window.innerWidth<700){
            this.params.zoom = 5;
        }
        // initialize the map on the "map" div with a given center and zoom
        this.map = L.map(this.$el, this.params);

        // Bounds
        // let mapBounds = this.map.getBounds();
        // this.map.setMaxBounds( [], [] );

        L.tileLayer('https://api.mapbox.com/styles/v1/mapbox/streets-v9/tiles/256/{z}/{x}/{y}?access_token={accessToken}', {
            attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a>',
            maxZoom: 18,
            id: 'mapbox.streets',
            accessToken: 'pk.eyJ1IjoiZGFua2Etc3R1ZGlvIiwiYSI6ImNqaWxpZzBjYzJmMHIzcW9jc2ptenl1bGQifQ.Cb5nbNixCAfBO3UuymMJwQ',
        }).addTo(this.map);
        L.control.zoom({
            position: 'bottomright'
        }).addTo(this.map);


        this.createMarkers();
        this.displayMarkers();
        this.filterChange();
    }
    createMarkers(){
        const refThis = this;

        const LeafIcon = L.Icon.extend({
            options: {
                iconUrl:    baseUrl + '/src/js/map/map-pin-blue.svg',
                iconSize:   [25.35, 35],
                iconAnchor: [12.5, 35],
                popupAnchor: [0, -40]
            }
        });
        let customIcon = new LeafIcon({iconUrl: baseUrl + '/src/js/map/map-pin-pink.svg'});

        this.mapData.forEach((el)=>{
            if(!el.lat || !el.lng){
                return;
            }

            // On vire les espaces indésirables, au cas ou
            el.lat = el.lat.replace(/\t/g, '');
            el.lng = el.lng.replace(/\t/g, '');

            const LeafIcon = L.Icon.extend({
                options: {
                    iconUrl:    baseUrl + '/src/js/map/map-pin-blue.svg',
                    iconSize:   [25.35, 35],
                    iconAnchor: [12.5, 35],
                    popupAnchor: [0, -40]
                }
            });
            let customIcon = new LeafIcon({iconUrl: baseUrl + '/src/js/map/map-pin-blue.svg'});

            // Déclaration contenu Tooltip custom
            let customPopupContent = `
            <p class="mapTooltip-title s-labeur2 cBlue">${el.title}</p>
            <address>
                ${(el.adresse_1 !== '') ? el.adresse_1 + '<br>' : ''}
                ${(el.adresse_2 !== '') ? el.adresse_2 + '<br>' : ''}
                ${el.zipcode} - ${el.city + '<br>'}
                ${(el.tel !== '') ? '<br>' + el.tel + '<br>' : ''}
                ${(el.mail !== '') ? el.mail + '<br>' : ''}
                ${(el.resp !== '') ? '<br>Responsable : <br>' + el.resp + '<br>' : ''}
            </address>
            `;

            if( el.categories.length > 0){
                customPopupContent += `<div class="mapTooltip-icons">`;

                // On change l'icône du marker et contenu de la tooltip en fonction de la catégorie
                if( el.categories.indexOf('cat_transport') !== -1 && el.categories.indexOf('cat_logistique') !== -1 ){
                    customIcon = new LeafIcon({iconUrl: baseUrl + '/src/js/map/map-pin-both.svg'});
                    customPopupContent += `<i class="icon house"></i><i class="icon truck"></i>`;
                } else if( el.categories.indexOf('cat_logistique') !== -1 ){
                    customIcon = new LeafIcon({iconUrl: baseUrl + '/src/js/map/map-pin-pink.svg'});
                    customPopupContent += `<i class="icon house"></i>`;
                } else if( el.categories.indexOf('cat_autres') !== -1 ){
                    customIcon = new LeafIcon({iconUrl: baseUrl + '/src/js/map/map-pin-lightblue.svg'});
                } else if( el.categories.indexOf('cat_transport') !== -1 ){
                    customPopupContent += `<i class="icon truck"></i>`;
                } else if( el.categories.indexOf('siege') !== -1 ){
                    customIcon = new LeafIcon({iconUrl: baseUrl + '/src/js/map/map-pin-both.svg'});
                }

                customPopupContent += `</div>`;
            }


            let marker = L.marker([el.lat, el.lng], {icon: customIcon});
            // Tooltip custom
            let mapTooltip = L.popup({
                className: 'mapTooltip',
                maxWidth:   320,
                autoClose: false
            }).setContent(customPopupContent)
            ;
            // On ajoute la tooltip au marker
            marker.bindPopup(mapTooltip);

            // On ajoute le marker Leaflet à chaque élément du tableau de données
            el.marker = marker;
        });

    }
    displayMarkers(markers = null){
    	if(this.markersLayer){
    		this.markersLayer.remove();
    	}


    	if(!markers){
    		markers = this.mapData;
    	}

        this.markersLayer = L.markerClusterGroup({
            showCoverageOnHover:false,
            spiderfyOnMaxZoom: false,
            maxClusterRadius: 30
        });

    	markers.forEach(el => {
        if(!el.lat || !el.lng){
            return;
        }
    		this.markersLayer.addLayer(el.marker);
    	});

        this.map.addLayer(this.markersLayer);
    }

    filterChange(){
        this.$listFilters.on('click', (e)=>{
            e.preventDefault();
            e.stopPropagation();

            if( !$(e.target).is('a') ) {
                return;
            }

            if(this.filters.length > 0){
                this.filters = [];
            }

            this.$listFilters.each((i, el)=>{
                if($(el).attr('data-filter') == $(e.target).attr('data-filter')){
                    $(el).toggleClass('selected');
                }
                if( $(el).hasClass('selected') ){
                    this.filters.push( $(el).attr('data-filter') );
                }
            });

            const selected = this.getSelectedMarkers();
        	this.displayMarkers(selected);
        });
    }
    getSelectedMarkers(){
        const filters = this.filters;
    	const markers = this.mapData;
    	const selected = [];

        markers.forEach(el => {
            let visible = true;

            // si on a des filtres sélectionnés
            if(filters.length > 0){
                // si l'élément n'a pas de catégories ou que les cat ne correspondent pas aux valeurs de filtre -> on le cache
                if(!el.categories
                || !el.categories.some(r=> filters.indexOf(r) >= 0)){
                    visible = false;
                }
            }

            if(visible){
    			selected.push(el);
    		}

            // Toujours montrer le pin du Siège Social
            if( el.categories.indexOf('siege') !== -1 ){
                selected.push(el);
            }
        });

        return selected;
    }
}

$(document).ready(function() {
    if( !$('#map-container').length ) {
        return;
    }

    let sites_map = new Map({
        element: 'map-container',
        parent: '#map-interactive',
        center: [46.589, 2.659],
        zoom: 6,
        minZoom: 4
    });
});
