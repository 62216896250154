import { TimelineLite } from 'gsap';

class Popin {
    constructor($el) {
        // const me = this;

        this.$el = $el;
        this.$trigger = $(".popin-trigger");
        this.popinTarget = this.$trigger.attr("data-popin");
        // this.popinID = this.$el.attr("id");
        this.$popinClose = $(".popin-close", this.$el);
        this.$popinOverlay = $(".popin-overlay");


        // Open Popin
        this.$trigger.on("click.popin", e => {
            e.preventDefault();

            const popinId = $(e.currentTarget).attr('data-popin');

            if ($("#" + popinId).length) {
                this.openPopin("#" + popinId);
            }
        });

        // Close Popin
        this.$popinClose.add(this.$popinOverlay).on("click.popin", e => {
            e.preventDefault();

            if ($("#" + this.popinTarget).length) {
                this.closePopin("#" + this.popinTarget);
            }
        });

        document.addEventListener("keydown", e => {
            if (e.key == "Escape") {
                this.closePopin("all");
            }
        });
    }

    openPopin(popin) {
        const $pop = popin == "all" ? $(".popin") : $(popin),
            $popInner = $(".popin-inner", $pop);
        const vTL = new TimelineLite({
            onStart: () => {
                $("body").css(
                    "margin-right",
                    window.innerWidth - document.documentElement.clientWidth
                );
                $("body").addClass("noScroll");
                $pop.removeClass("isHidden");
            },
        })
            .set($pop, { display: "flex" })
            .set(this.$popinOverlay, { display: "block" })
            .fromTo(
                this.$popinOverlay,
                0.4,
                { autoAlpha: 0 },
                { autoAlpha: 1, ease: Power3.easeIn }
            )
            .fromTo(
                $popInner,
                0.4,
                { y: -80, autoAlpha: 0 },
                { y: 0, autoAlpha: 1, ease: Power2.easeIn, force3D: true },
                0.3
            );
    }
    closePopin(popin) {
        const $pop = popin == "all" ? $(".popin") : $(popin),
            $popInner = $(".popin-inner", $pop);
        const vTL = new TimelineLite({
            onComplete: () => {
                $pop.hide();
                $pop.removeClass("isHidden");
                $("body").removeClass("noScroll");
                $("body").css("margin-right", 0);
            },
        })
            .to($popInner, 0.4, {
                y: -80,
                autoAlpha: 0,
                ease: Power2.easeIn,
                force3D: true,
            })
            .to(this.$popinOverlay, 0.4, { autoAlpha: 0, ease: Power3.easeIn }, 0.2)
            .set(this.$popinOverlay, { display: "none" });
    }
}
export default Popin;

//------------------------------------------
// Init Popin
//------------------------------------------
$(document).ready(function () {
    let $popin = $(".popin");
    let pop = [];

    $popin.each((i, el) => {
        pop[i] = new Popin($(el));
    });
});
