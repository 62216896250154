import Slider from './Slider.js';
import Flickity from 'flickity';

class SliderTemoins extends Slider{
    constructor($self,params = null){

        super($self, params);

        const me = this;

        this.prevCurrent = this.slider.selectedIndex;

        this.sliderData = Flickity.data( me.stage[0] );

        // On init les clicks
        if(this.pagination.length){
            this.initPagination();
        }

        this.slider.on('change', (index)=>{
            this.animateSlider(index);
        });

        $(window).on('resize', ()=>{
            me.stage.addClass('is-resizing');

            if(me.sliderData.isActive){
                me.slider.resize();
                me.sliderData.updateDraggable();
            }
            setTimeout(()=>{
                me.stage.removeClass('is-resizing');
            }, 250);
        });
    }

    initPagination(){
        const me = this;
        this.pagination.eq(me.slider.selectedIndex).addClass('active');
        this.pagination.on('click', (e)=>{
            e.preventDefault();

            if( !this._isActive ){
                let index = $(e.currentTarget).index();
                me.slider.select( index );
                this.pagination.removeClass('active');
                $(e.currentTarget).addClass('active');
            }
        });
    }

    animateSlider(index){
        const me = this;

        if(!this._isActive){
            let $prevSlide = $(this.slider.cells[this.prevCurrent].element),
                $nextSlide =  $(this.slider.cells[index].element)
            ;

            let otherSlidesKeys = Object.keys(this.slider.cells).filter((key)=>{
                return key != index && key != me.prevCurrent;
            });

            this._isActive = true;

            const vTL = new TimelineLite({
                onComplete: ()=>{
                    me._isActive = false;
                    me.prevCurrent = me.slider.selectedIndex;
                }
            });

            otherSlidesKeys.forEach((key)=>{
                vTL.set($(this.slider.cells[key].element), {zIndex:0, autoAlpha:0},0);
            });

            vTL.to($prevSlide, 0.6, {y:-200, force3D:true, ease:Power2.easeOut})
            .to($prevSlide, 0.25, {autoAlpha:0}, 0)
            .set($prevSlide, {zIndex:0})
            .set($nextSlide, {zIndex:2})
            .fromTo($nextSlide, 0.6, {y:200}, {y:0, force3D:true, ease:Power2.easeOut}, 0.5)
            .fromTo($nextSlide, 0.3, {autoAlpha:0}, {autoAlpha:1}, "-=0.5")
            ;

            return vTL;
        }
    }

}
export default SliderTemoins;


//------------------------------------------
// Init Slider Temoins
//------------------------------------------
$(document).ready(function() {
    const $slider_temoins = $('.temoins.slider');
    let $slider_stage;

    if($slider_temoins.length){
        var sliderTemoins = [];

        $slider_temoins.each((i, el)=>{
            $slider_stage = $('.slider-stage', $(el));

            sliderTemoins[i] = new SliderTemoins($(el), {
                groupCells: false,
                prevNextButtons: false,
                autoPlay: 5000,
                wrapAround: true
            });
        });
    }
});
