import Flickity from 'flickity';

class Slider{
    constructor($el,params = null){

        const me = this;

        var _defaultParams = {
            cellAlign: 'left',
            contain: true,
            pageDots: false,
            wrapAround: false,
            draggable: '>1',
            selectedAttraction: 0.02,
            pauseAutoPlayOnHover: false
        }

        this.$el = $el;
        // Si il y a des paramètres en plus, on vient fusionner l'objet par defaut avec le nouveau.
        this.params = $.extend(_defaultParams,params);
        this.slider;
        this.slide = $('.slide',this.$el);
        this.stage = $('.slider-stage', this.$el);
        this.next = $('.slider-nav .slider-nav-next',this.$el);
        this.prev = $('.slider-nav .slider-nav-prev',this.$el);
        this.pagination = $('.slider-dots .slider-dot', this.$el);
        this.counter = $('.slider-counter', this.$el);
        this.length = this.slide.length;
        this._isActive = false;

        this.stage.on( 'ready.flickity', (i)=>{
            me.stage.addClass('flickity-ready');
        });
        this.slider = new Flickity( me.stage[0], me.params);
        this.sliderData = Flickity.data( me.stage[0] );

        if( this.params.draggable !== false ){
            this.slider.on( 'dragStart', ()=> {
                me.stage.addClass('is-dragging');
            });

            this.slider.on( 'dragEnd', ()=>{
                me.stage.removeClass('is-dragging');

                if(me.params.autoPlay){
                    me.slider.playPlayer();
                }
            });
        }

        // On init les clicks
        this.initNavigation();

        if(this.pagination.length){
            this.initPagination();

            this.slider.on('change', (index)=>{
                me.pagination.removeClass('active');
                me.pagination.eq(index).addClass('active');
            });
        }

        $(window).on('resize', ()=>{
            me.stage.addClass('is-resizing');

            if(me.sliderData.isActive){
                me.slider.resize();
                me.sliderData.updateDraggable();
            }
            setTimeout(()=>{
                me.stage.removeClass('is-resizing');
            }, 250);
        });
    }

    initNavigation(){
        const me = this;
        this.next.click(function(e){
            e.preventDefault();

            if( !this._isActive ){
                me.slider.next();
            }
        });
        this.prev.click(function(e){
            e.preventDefault();
            if( !this._isActive ){
                me.slider.previous();
            }
        });
    }
    initPagination(){
        const me = this;
        this.pagination.eq(me.slider.selectedIndex).addClass('active');
        this.pagination.on('click', (e)=>{
            e.preventDefault();

            if( !this._isActive ){
                let index = $(e.currentTarget).index();
                me.slider.select( index );
                this.pagination.removeClass('active');
                $(e.currentTarget).addClass('active');
            }
        });
    }
}
export default Slider;


//------------------------------------------
// Init Slider de base (horizontal)
//------------------------------------------
$(document).ready(function() {
    const $slider_base = $('.slider:not(.cover):not(.temoins)');

    if($slider_base.length){
        var sliderBase = [];

        $slider_base.each((i, el)=>{
            sliderBase[i] = new Slider($(el), {
                groupCells: true,
                watchCSS: true
            });
        });
    }
});
