import Slider from './Slider.js';
import Flickity from 'flickity';

class SliderOnglets extends Slider{
    constructor($self,params = null){

        super($self, params);

        const me = this;

        this.$self = $self;

        this.prevCurrent = this.slider.selectedIndex;

        this.slider = new Flickity( me.stage[0], me.params);
        this.$navSlider = $('.icons', this.$self);
        this.navSlider = new Flickity( me.$navSlider[0], {
            pageDots: false,
            prevNextButtons: false,
        });

        this.sliderData = Flickity.data( me.stage[0] );
        this.navSliderData = Flickity.data( me.$navSlider[0] );

        // A la main car le "As Nav For" de flickity est un peu contraignant
        this.navSlider.cells.forEach((elem, i)=>{
            $(elem.element).click((e)=>{
                e.preventDefault();

                this.navSlider.select(i);
            });
        });

        this.navSlider.on('change', (index)=>{
            this.slider.select(index);
        });

        this.slider.on('change', (index)=>{
            this.animateSlider(index);
        });

        $(window).on('resize', ()=>{
            if(me.sliderData.isActive){
                me.slider.resize();
                me.sliderData.updateDraggable();
            }
            if(me.navSliderData.isActive){
                me.navSliderData.resize();
                me.navSliderData.updateDraggable();
            }
        });
    }

    animateSlider(index){
        const me = this;

        if(!this._isActive){
            me._isActive = true;

            let $prevOnglet = $(me.slider.cells[me.prevCurrent].element),
                $pOngletText = $('.s-title3, .wysiwyg, .link-wrap', $prevOnglet),
                $pOngletImg = $('.imageFit', $prevOnglet),
                $nextOnglet = $(me.slider.cells[index].element),
                $nOngletText = $('.s-title3, .wysiwyg, .link-wrap', $nextOnglet),
                $nOngletImg = $('.imageFit', $nextOnglet)
            ;

            let otherOngletsKeys = Object.keys(this.slider.cells).filter((key)=>{
                return key != index && key != me.prevCurrent;
            });

            const vTL = new TimelineLite({
                onComplete: ()=>{
                    me._isActive = false;
                    me.prevCurrent = me.slider.selectedIndex;
                }
            });

            otherOngletsKeys.forEach((key)=>{
                vTL.set($(this.slider.cells[key].element), {zIndex:0},0);
            });


            vTL.set($nextOnglet, {zIndex:1})
            .set($nOngletImg, {autoAlpha:1})
            .staggerFromTo($pOngletText, 0.4, {autoAlpha:1, y:0}, {autoAlpha:0, y:-50, force3D:true, ease:Power1.easeOut}, 0.07)
            .fromTo($pOngletImg, 0.5, {autoAlpha:1}, {autoAlpha:0}, 0.5)
            .to($prevOnglet, 0.1, {className:'-=active', zIndex:1})
            .to($nextOnglet, 0.1, {className:'+=active', zIndex:2})
            .staggerFromTo($nOngletText, 0.4, {autoAlpha:0, y:50}, {autoAlpha:1, y:0, force3D:true, ease:Power1.easeOut}, 0.1, "-=0.2")
            ;

            return vTL;
        }
    }

}
export default SliderOnglets;


//------------------------------------------
// Init Slider Onglets
//------------------------------------------
$(document).ready(function() {
    const $slider_onglets = $('.onglets');

    if($slider_onglets.length){
        var sliderOnglets = [];

        $slider_onglets.each((i, el)=>{
            sliderOnglets[i] = new SliderOnglets($(el), {
                groupCells: false,
                wrapAround: false,
                draggable: false
            });
        });
    }
});
